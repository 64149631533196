import React from "react"

import Layout from "../../../components/Layout/layout"
import SEO from "../../../components/SEO/seo"
import HeroInner from "../../../components/HeroInner/hero-inner"
import ArticleFullWidth from "../../../components/ArticleFullWidth/article-full-width"

const Fulfillment = () => (
  <Layout>
    <SEO title="Fulfillment" />
    <HeroInner
      title="Fulfillment"
      imageAlt="Fulfillment"
      imagePath="services/tailored-services/fulfillment/hero.jpg"
    />
    <ArticleFullWidth
      descriptions={[
        `No ecommerce business wants to miss out on potential sales, yet many do when they choose not to ship to the US & UK. We provide order fulfilment service that allows our clients to establish a presence in the US & UK. Blend-Divine fulfils orders placed with our clients via our warehouses located in the US & UK. We have collaboration with multiple warehouses all over US & UK which can store your products and provide various delivery services to your end customer.`,
      ]}
    />
  </Layout>
)

export default Fulfillment
